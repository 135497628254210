<template>
    <div>
       <v-fab-transition>
         <v-icon class="primary onPrimary--text pa-3 icon">{{icon}}</v-icon>
     </v-fab-transition>
    </div>
</template>
<script>
export default {
    props: ['icon']
}
</script>
