<template>
    <div>
      <div v-for="address in addressUser" :key="address.id">
      <v-card class="mt-1 d-flex align-center card flat-card" color="surface" height="60" @click="push(address.id)">
        <v-row class="pa-3 d-flex align-center">
          <v-icon v-if="address.type == 'work'" class="onSurface--text mr-2 ml-1">mdi-office-building</v-icon>
          <v-icon v-if="address.type == 'home'" class="onSurface--text mr-2 ml-1">mdi-home</v-icon>
          <h5 class="onSurface--text medium-font" v-text="address.title"></h5>
          <v-spacer></v-spacer>
          <v-icon class="primaryText--text ml-2">mdi-chevron-left</v-icon>
        </v-row>
      </v-card>
      </div>
    </div>
</template>
<script>
import router from '@/router'
import { viewAddressUser } from '../../models/address'
import { mapState } from 'vuex'
export default {
  // props: ['address'],
   methods: {
        push(locationID) {
          viewAddressUser(locationID)
          router.push(`/location/${locationID}`)
        }
    },
    computed: {
      ...mapState({
        addressUser: state => state.address.UserAddress
      })
    }
}
</script>
